import React, { MutableRefObject, useContext, useEffect, useState } from 'react'
import { uniq } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ActiveArrow, Cross, DefaultArrow, FilterCross } from './Svg'
import DropDownMenu from './DropDownMenu'
import { Context } from './BaseContext'
import { useQueryParams } from '../../hooks/queryString'
import { ILeftSlideBarProps } from '../../types/components'
import { getPerkLists } from '../../state/perks/selectors'
import { fetchByPerkByTag, fetchPerksFilterTag } from '../../state/perks/actions'
import { getRecipe } from '../../state/recipe/selectors'
import { fetchRecipeTagsList } from '../../state/recipe/actions'
import { ITags } from '../../types/data'

interface IProps {
    modalRef: MutableRefObject<null>
    handleCloseModal: () => void
    leftSidebarData: ILeftSlideBarProps
    open: boolean
}

export default function LeftFilterSidebarMobile({ modalRef, handleCloseModal, open, leftSidebarData }: IProps) {
    const { screenWidth } = useContext(Context)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [openedMenu, setOpenedMenu] = useState([1])
    const { perksFilterTag } = useSelector(getPerkLists)
    const { tags } = useQueryParams()
    const tagsList = typeof tags === 'string' ? tags?.split(',') : []
    // @ts-ignore
    const [openMenu, setOpenMenu] = useState(!tags?.length > 0 && screenWidth <= 1199)
    const { recipeTags } = useSelector(getRecipe)
    const menu = leftSidebarData.buttonName === 'perks' ? perksFilterTag : recipeTags

    console.log('leftSidebarData', leftSidebarData)

    useEffect(() => {
        dispatch(fetchPerksFilterTag)
        dispatch(fetchRecipeTagsList)
        // eslint-disable-next-line
    }, [])

    const onChangeValueTag = (item: ITags) => {
        const newTags = tagsList.find((i) => i === item.id.toString())
            ? tagsList.filter((i) => i !== item.id.toString())
            : [...tagsList, item.id]
        if (leftSidebarData?.buttonName === 'swaps') {
            if (newTags.length > 0) {
                // eslint-disable-next-line no-unused-expressions
                navigate(`/lower/swaps/swap-filter-by-tag?tags=${newTags.join(',')}`)
                dispatch(fetchByPerkByTag(newTags.toString()))
            } else {
                navigate('/lower/swaps')
            }
        } else if (leftSidebarData?.buttonName === 'recipes') {
            if (newTags.length > 0) {
                // eslint-disable-next-line no-unused-expressions
                navigate(`/lower/recipes/filter-by-tag?tags=${newTags.join(',')}`)
            } else {
                navigate('/lower/recipes')
            }
        }
    }

    const reset = () => {
        if (leftSidebarData?.buttonName === 'perks') {
            navigate('/lower/swaps/swap-filter-by-tag')
        } else if (leftSidebarData?.buttonName === 'recipes') {
            navigate('/lower/recipes/filter-by-tag')
        }
    }

    const handleOpenDropDown = (id:number) => {
        if (!openedMenu.includes(id)) {
            setOpenedMenu(uniq([...openedMenu, id]))
        } else {
            setOpenedMenu(openedMenu.filter((item) => item !== id))
        }
    }

    return (
        <div>
            <div ref={modalRef} className={`z-[1000] overflow-y-auto top-0 left-0 w-[300px] bg-pistachio text-white fixed h-full ease-in-out duration-300 ${open ? 'translate-x-0' : '-translate-x-full'}`}>
                <div className="bg-pistachio sticky top-0 flex items-center justify-between h-[56px] border-b-[1px] border-avocado px-[20px]">
                    <div onClick={handleCloseModal}>
                        <FilterCross width={24} height={24} />
                    </div>

                    <span onClick={reset} className={`${tags === undefined ? 'hidden' : 'block'} font-medium text-14 text-rocket tracking-[-0.01rem] leading-[15.4px]`}>
                        Reset filters
                    </span>
                </div>

                <div className="mr-10 mt-[32px] pl-6">
                    {leftSidebarData?.buttonName === 'perks' ? (
                        leftSidebarData?.data?.slice(0, 2)?.map((item) => (
                            <div key={item.id}>
                                <DropDownMenu
                                    loadContent={item.loadContent}
                                    isOpen={!openedMenu.includes(item.id)}
                                    onOpen={() => {
                                        handleOpenDropDown(item.id)
                                    }}
                                    title={item.title}>
                                    {item.content}
                                </DropDownMenu>
                            </div>
                        ))
                    ) : (
                        leftSidebarData?.data?.slice(0, 1)?.map((item) => (
                            <div key={item.id}>
                                <DropDownMenu
                                    loadContent={item.loadContent}
                                    isOpen={!openedMenu.includes(item.id)}
                                    onOpen={() => {
                                        handleOpenDropDown(item.id)
                                    }}
                                    title={item.title}>
                                    {item.content}
                                </DropDownMenu>
                            </div>
                        ))
                    )}
                </div>

                <div>
                    <button className="flex items-center rounded-lg text-sm font-medium leading-5" onClick={() => setOpenMenu(!openMenu)}>
                        <span className="heading-uppercase heading-font pl-6 font tracking-tight font-medium text-18 text-rocket">
                            Tags
                        </span>

                        <div className="flex items-center justify-center ml-2 w-[22px]">
                            {openMenu ? (
                                <DefaultArrow width={13} height={8} />
                            ) : (
                                <ActiveArrow width={13} height={8} />
                            )}
                        </div>
                    </button>

                    <div className={`mb-[32px] ${openMenu ? 'transform scale-y-0 h-0' : ''}`}>
                        <div className="flex flex-wrap mr-10 mt-[16px] pl-6">
                            {menu?.map((item) => {
                                // @ts-ignore
                                const isSelected = tagsList.includes(String(item.id))

                                return (
                                    <button onClick={() => onChangeValueTag(item)} key={item.id}
                                        className={`h-[32px] rounded-[30px] flex items-center justify-center cursor-pointer mb-[8px] mr-2 ${isSelected ? 'px-3 bg-cavolo' : 'px-5 bg-rocket'} `}>

                                        <span className="font-semibold text-pistachio hover:font-bold hover:ease-in hover:duration-200"
                                            style={{ fontSize: 12 }}>{item.title}
                                        </span>

                                        {isSelected ? (
                                            <div className="ml-1.5">
                                                <Cross width={10} height={10} />
                                            </div>
                                        ) : null}
                                    </button>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
