import React, { useEffect, useRef } from 'react'
import { Field } from 'formik'
import { FieldProps } from 'formik/dist/Field'
import PhoneInput, { CountryData } from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useSelector } from 'react-redux'
import ValidationErrorMessage from './ValidationErrorMessage'
import { getLoginConfig, getWhiteLabelingList } from '../../state/user/selectors'

interface IProps {
    name: string
    label?: string
    component?: string
    size?: number
    placeholder?: string
    type?: string
    validate?: any
    className?: string
    error?: string | {
        nonFieldErrors: string[]
        detail: string[]
    }
    defaultValue?: string
    onCountryChange?: (countryCode: string) => void
    setCountryCode?: (value: string) => void
    countryCode?: string
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

// eslint-disable-next-line react/function-component-definition
const CustomPhoneInput: React.FC<IProps> = ({ label, defaultValue, countryCode, setCountryCode, onCountryChange, name, validate, ...attributes }: IProps) => {
    const { loginConfig } = useSelector(getLoginConfig)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)

    const phoneNumberRef = useRef(null)

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Tab') {
            const { form } = event.currentTarget
            if (!form) return

            const elements = Array.from(form.elements) as HTMLElement[]
            const index = elements.indexOf(event.currentTarget)
            const nextIndex = event.shiftKey ? index - 1 : index + 1

            if (nextIndex >= 0 && nextIndex < elements.length) {
                event.preventDefault()
                elements[nextIndex].focus()
            }
        }
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            const flagElement = document.querySelector('.react-tel-input .selected-flag')
            if (flagElement) {
                flagElement.setAttribute('tabindex', '-1')
                clearInterval(intervalId)
            }
        }, 100)

        return () => clearInterval(intervalId)
    }, [])

    const handleOnChange = (value: string, data: CountryData) => {
        if (setCountryCode) {
            setCountryCode(data.countryCode)
        }
        if (onCountryChange) {
            onCountryChange(data.countryCode)
        }
    }

    return (
        <>
            {label && (
                <label htmlFor={name} className="text-black text-14 mb-1.5 block font-medium font">
                    {label}
                </label>
            )}

            <style>
                {`
                    .flag-dropdown .selected-flag {
                        pointer - events: none; /* Disable pointer events */
                        tab-index: -1; /* Ensure the flag is not focusable */
                    }
                `}
            </style>

            <Field name={name} validate={validate}>
                {({ field: { value }, form: { setFieldValue } }: FieldProps) => (
                    <PhoneInput
                        inputProps={{ ref: phoneNumberRef, tabIndex: 0 }}
                        onKeyDown={handleKeyDown}
                        country={countryCode || (loginConfig?.defaultCountry || 'gb')}
                        buttonStyle={{
                            borderColor: loginConfig?.whiteLabeling?.avocado || whiteLabeling?.avocado,
                            background: '#fff',
                            borderTopLeftRadius: 8,
                            borderBottomLeftRadius: 8,
                        }}
                        inputStyle={{
                            borderColor: loginConfig?.whiteLabeling?.avocado || whiteLabeling?.avocado,
                            width: '100%',
                            height: 50,
                            borderRadius: 8,
                            color: loginConfig?.whiteLabeling?.black || whiteLabeling?.black,
                            fontWeight: 500,
                            fontSize: 16,
                            lineHeight: '24px',
                        }}
                        value={value || defaultValue}
                        onChange={(phone: string, data: CountryData) => {
                            setFieldValue(name, phone)
                            handleOnChange(phone, data)
                        }}
                        enableLongNumbers
                        {...attributes} />
                )}
            </Field>

            <ValidationErrorMessage name={name} />
        </>
    )
}

export default CustomPhoneInput
