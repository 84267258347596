import React, { useContext, useState } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MoreButton, TravelTreeRedLine, TravelTreeSmallEllipse } from '../common/Svg'
import Button from '../common/Button'
import { displayDistanceWithUnit, readableC02, replaceAll } from '../../utils/helpers'
import { ILegs } from '../../types/data'
import { travelSelector } from '../../state/travel/selectors'
import { COLOR_CONST } from '../../constants'
import QuizQuitOverlayModal from '../quiz/QuizQuitOverlayModal'
import { getUser, getWhiteLabelingList } from '../../state/user/selectors'
import { Context } from '../common/BaseContext'
import EditLegModal from './EditLegModal'

interface IProps {
    missingLegFromTop: boolean
    isLast: boolean
    goingBackCommute?: any
    item: ILegs
    index: number
    impact?: boolean
    legs: ILegs[]
    reloadStoredData?: () => void
    onSubmit: any
    journeyData: any
    showHideDetails?: boolean
    setShowHideDetails: (value: boolean) => void
}

interface IMissingLegProps {
    legStartEnd: {
        startPlaceName?: string
        startPlaceId?: string
        startPlaceLon?: string
        startPlaceLat?: string
        endPlaceName?: string
        endPlaceId?: string
        endPlaceLon?: string
        endPlaceLat?: string
    }
    createLegPosition: number
    t: any
    impact: any
    goingBackCommute: boolean
    navigate: any
    journeyData: any
    reloadStoredData?: () => void
    previousLeg: ILegs
    nextLeg?: ILegs
    setShowHideDetails?: (value: boolean) => void
    legs: ILegs[]
    isLast?: boolean
    showHideDetails?: boolean
    missingLegFromBottom?: boolean
}

function MissingLegComponent({ missingLegFromBottom, showHideDetails, isLast, legs, setShowHideDetails, legStartEnd, createLegPosition, t, impact, goingBackCommute, navigate, journeyData, reloadStoredData, nextLeg, previousLeg }: IMissingLegProps) {
    const [showEditLegModal, setShowEditLegModal] = useState<boolean>(false)

    return (
        <div className="flex pl-3 my-3 justify-between items-center">
            <div className="flex items-center">
                <TravelTreeRedLine />

                <div className="ml-5 pl-1 text-16 text-raspberry">
                    {t('log.unknown-journey-leg')}

                    {missingLegFromBottom && (
                        !showHideDetails && legs?.length > 1 && isLast && (
                            <h1 onClick={() => setShowHideDetails?.(true)}
                                className="cursor-pointer mt-2 mb-[-12px] text-black underline underline-offset-2 text-14">
                                {t('log.hide-details')}
                            </h1>
                        )
                    )}
                </div>
            </div>

            <Button text="Add leg details" className="w-max bg-lime border-raspberry text-cavolo" style={{ marginTop: 0 }}
                onClick={() => {
                    if (impact) {
                        navigate('/log/track-travel-overview', { state: { impact: true, goingBackCommute } })
                        return
                    }

                    setShowEditLegModal(true)

                    // localStorage.setItem(goingBackCommute ? 'returnTravelData' : 'travelData', JSON.stringify({
                //     ...storedData,
                //     createLegPosition,
                //     legStartEnd,
                // }))
                //
                // navigate('/log/track-travel-modality', { state: { goingBackCommute } })
                }} />

            {showEditLegModal && (
                <EditLegModal legs={legs} goingBackCommute={goingBackCommute} unknownLegData={{
                    createLegPosition,
                    nextLeg: nextLeg || {
                        startPlaceId: journeyData.endPlaceId,
                        startPlaceName: journeyData.endPlaceName,
                        startPlaceLon: journeyData.endPlaceLon,
                        startPlaceLat: journeyData.endPlaceLat,
                    },
                    previousLeg: previousLeg || {
                        endPlaceId: journeyData.startPlaceId,
                        endPlaceName: journeyData.startPlaceName,
                        endPlaceLon: journeyData.startPlaceLon,
                        endPlaceLat: journeyData.startPlaceLat,
                    },
                    legStartEnd,
                }} reloadStoredData={reloadStoredData} onClose={() => {
                    setShowEditLegModal(false)
                }} />
            )}
        </div>
    )
}

export default function LegItem({ missingLegFromTop, isLast, goingBackCommute, item, index, impact, legs, reloadStoredData, onSubmit, journeyData, showHideDetails, setShowHideDetails }: IProps) {
    const { transportModes, travelCommuteDetail } = useSelector(travelSelector)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const [t] = useTranslation('global')
    const { distanceUnit } = useContext(Context)
    const { user } = useSelector(getUser)

    const navigate = useNavigate()
    const isFirst = index === 0

    const [showQuizQuitOverlayModal, setShowQuizQuitOverlayModal] = useState<boolean>(false)
    // const [showDeleteLegOutboundModal, setShowDeleteLegOutboundModal] = useState<boolean>(false)

    const lowerTransportName = item?.transportName?.toLowerCase() || item?.transport?.name?.toLowerCase()

    const transportMode = transportModes.find((i) => i.name === lowerTransportName || i?.synonyms?.split(',').includes(lowerTransportName))

    const previousLeg = !isFirst ? legs[index - 1] : undefined

    // const travelData = JSON.parse(localStorage.getItem(goingBackCommute ? 'returnTravelData' : 'travelData') || '{}')

    // const handleDelete = () => {
    //     const isLastLeg = travelData.legs?.length === 1
    //     setShowQuizQuitOverlayModal(isLastLeg)
    //     setShowDeleteLegOutboundModal(!isLastLeg)
    // }

    const missingLegFromBottom = item.endPlaceName !== journeyData.endPlaceName && isLast

    return (
        <div>
            {missingLegFromTop && (
                <MissingLegComponent
                    legs={legs}
                    previousLeg={legs[index - 1]}
                    nextLeg={legs[index]}
                    reloadStoredData={reloadStoredData}
                    journeyData={journeyData}
                    navigate={navigate}
                    goingBackCommute={goingBackCommute}
                    impact={impact}
                    t={t}
                    createLegPosition={item.position || 0}
                    legStartEnd={{
                        endPlaceName: item.startPlaceName,
                        endPlaceId: item.startPlaceId,
                        endPlaceLon: item.startPlaceLon,
                        endPlaceLat: item.startPlaceLat,
                        ...(!previousLeg ? {
                            startPlaceName: journeyData.startPlaceName,
                            startPlaceId: journeyData.startPlaceId,
                            startPlaceLon: journeyData.startPlaceLon,
                            startPlaceLat: journeyData.startPlaceLat,
                        } : {
                            startPlaceName: previousLeg.endPlaceName,
                            startPlaceId: previousLeg.endPlaceId,
                            startPlaceLon: previousLeg.endPlaceLon,
                            startPlaceLat: previousLeg.endPlaceLat,
                        }),
                    }} />
            )}

            <div key={item?.backendLegId} className={cn('border-spinach border-l-[10px] ml-3 pl-5', {
                'pb-7': isLast && !missingLegFromBottom,
                'pt-6': !missingLegFromTop,
                'mt-[-3px]': isFirst,
            })}>
                {missingLegFromTop && (
                    <div className="flex relative top-[-8px] items-center pb-4">
                        <div className="absolute left-[-33.7px]">
                            <TravelTreeSmallEllipse />
                        </div>

                        <div className="text-16 text-cavolo font-semibold not-heading-font">
                            {item.startPlaceName}
                        </div>
                    </div>
                )}

                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        {/* eslint-disable-next-line react/no-danger */}
                        <div className="mr-1.5" dangerouslySetInnerHTML={{ __html: replaceAll(transportMode?.icon || '', COLOR_CONST, whiteLabeling.cavolo) }} />

                        <div>
                            <h1 className="text-14 tracking-tight text-cavolo xs-mobile:w-[337px]">
                                {item?.transportName || item?.transport?.name}, {displayDistanceWithUnit(Number(item?.travelDistanceKilometres || 0), distanceUnit, t)}
                            </h1>

                            <h1 className="text-14 tracking-tight text-cavolo xs-mobile:w-[337px]">
                                {readableC02(item?.ceo2Kg || 0)}
                            </h1>
                        </div>
                    </div>

                    {(!impact && !travelCommuteDetail?.multiDaySections?.length) && (
                        <div className="flex items-center cursor-pointer" onClick={() => onSubmit(item)}>
                            {/* <div onClick={() => edit(item, index)}> */}
                            {/*   <EditIcon /> */}
                            {/* </div> */}

                            {/* <div onClick={handleDelete}> */}
                            {/*    <TrashIcon /> */}
                            {/* </div> */}
                            <MoreButton />
                        </div>
                    )}
                </div>

                {(!isLast || missingLegFromBottom) && (
                    <div>
                        <div className="flex relative bottom-[-8px] pt-7 items-center">
                            <div className="absolute left-[-33.7px]">
                                <TravelTreeSmallEllipse />
                            </div>

                            <div className="text-16 text-cavolo font-semibold not-heading-font">
                                {item.endPlaceName}
                            </div>
                        </div>
                    </div>
                )}

                {!missingLegFromBottom && (
                    !showHideDetails && legs.length > 1 && isLast && (
                        <h1 onClick={() => setShowHideDetails(true)} className="cursor-pointer mt-2 mb-[-12px] text-black underline underline-offset-2 text-14">
                            {t('log.hide-details')}
                        </h1>
                    )
                )}
            </div>

            {missingLegFromBottom && (
                <MissingLegComponent
                    setShowHideDetails={setShowHideDetails}
                    missingLegFromBottom={missingLegFromBottom}
                    legs={legs}
                    isLast={isLast}
                    showHideDetails={showHideDetails}
                    previousLeg={legs[index]}
                    reloadStoredData={reloadStoredData}
                    journeyData={journeyData}
                    navigate={navigate}
                    goingBackCommute={goingBackCommute}
                    impact={impact}
                    t={t}
                    createLegPosition={(travelCommuteDetail?.legs?.length || 0) + 1}
                    legStartEnd={{
                        startPlaceName: item.endPlaceName,
                        startPlaceId: item.endPlaceId,
                        startPlaceLon: item.endPlaceLon,
                        startPlaceLat: item.endPlaceLat,
                        endPlaceName: journeyData.endPlaceName,
                        endPlaceId: journeyData.endPlaceId,
                        endPlaceLon: journeyData.endPlaceLon,
                        endPlaceLat: journeyData.endPlaceLat,
                    }}
                />
            )}

            {/* <DeleteLegModal */}
            {/*    legs={legs} */}
            {/*    goingBackCommute={goingBackCommute} */}
            {/*    overviewLeg={overviewLeg} */}
            {/*    title={t('log.are-you-sure-you-want-to-delete-this-leg?')} */}
            {/*    leg={item} */}
            {/*    outboundLeg={false} */}
            {/*    isShown={showDeleteLegOutboundModal} */}
            {/*    onContinue={() => setShowDeleteLegOutboundModal(false)} */}
            {/* /> */}

            <QuizQuitOverlayModal
                description={user?.company?.gamification ? t('log.quit-description-is-switched-on-gamification') : t('log.quit-description-is-switched-off-gamification')}
                isShown={showQuizQuitOverlayModal}
                onClose={() => navigate('/me/impact')}
                onContinue={() => setShowQuizQuitOverlayModal(false)} />
        </div>
    )
}
