import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import cn from 'classnames'
import { Form, Formik } from 'formik'
import { ArrowTravel,
    DifferentJourneyDifferentDestinationIcon,
    DifferentJourneySameDestinationIcon,
    DismissIcon, HearthActiveIcon, HearthDefaultIcon,
    OneWayJourneyIcon,
    PlusAddLeg,
    SameReturnJourneyIcon } from '../../components/common/Svg'
import Button from '../../components/common/Button'
import QuizQuitOverlayModal from '../../components/quiz/QuizQuitOverlayModal'
import { Context } from '../../components/common/BaseContext'
import Layout from '../../components/layout/Layout'
import { getHomePageLists } from '../../state/home/selectors'
import SponsoredByBlock from '../../components/SponsoredByBlock'
import { clearTravelData, legInstancesToFormData } from '../../utils/travel'
import JourneyLegs from '../../components/travel/JourneyLegs'
import AddLegModal from '../../components/travel/AddLegModal'
import { getUser } from '../../state/user/selectors'
import { ILeg } from '../../types/data'
import { changeCommuteDestination,
    clearLegTransport,
    commuteSave,
    getTravelCommuteDetail,
    multipleSaveLegs } from '../../state/travel/actions'
import { legTransportList } from '../../state/travel/selectors'
import Input from '../../components/common/Input'
import { required } from '../../utils/validators'
import { replacePartsOfText } from '../../utils/helpers'

function ReturnTypes({ Svg, text, typeName, onChangeType, selectedReturnJourneyType }: {Svg: any, text: string, typeName: string, onChangeType: (value: string) => void, selectedReturnJourneyType: string}) {
    const isActive = selectedReturnJourneyType === typeName

    return (
        <div
            onClick={() => onChangeType(typeName)}
            className={cn('mr-3 w-[90px] h-[140px] flex flex-col rounded-[10px] items-center pt-3', { 'bg-cavolo': isActive, 'bg-pistachio border-mint border-2': !isActive })}
            style={{ boxShadow: isActive ? '2px 2px 0px 0px rgba(0, 0, 0, 0.15)' : undefined }}>
            <Svg isActive={isActive} />
            <span className={cn('text-12 mt-2 text-center', { 'text-pistachio': isActive, 'text-cavolo': !isActive })}>{text}</span>
        </div>
    )
}

export default function TrackTravelOverview() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { pathname } = useLocation()
    const { isEmbedded, screenWidth, navbarHeight } = useContext(Context)
    const { user } = useSelector(getUser)
    const { commuteStartEnd, travelCommuteDetail } = useSelector(legTransportList)
    const [t] = useTranslation('global')
    const [showQuizQuitOverlayModal, setShowQuizQuitOverlayModal] = useState<boolean>(false)
    const [showAddLegModal, setShowAddLegModal] = useState<any>('')
    const distanceNumberBack = location?.state?.distanceNumber
    const [outboundData, setOutboundData] = useState<any>(JSON.parse(localStorage.getItem('travelData') || '{}'))
    const [returnData, setReturnData] = useState<any>(JSON.parse(localStorage.getItem('returnTravelData') || '{}'))
    const [selectedReturnJourneyType, setSelectedReturnJourneyType] = useState(location?.state?.defaultTile || 'same-as-outbound')
    const [loading, setLoading] = useState<boolean>(false)
    const [heart, setHeart] = useState<boolean>(false)
    const [favouriteRouteName, setFavouriteRouteName] = useState<string>('')
    const isReturnJourneyExists = returnData.startPlaceId

    // @ts-ignore
    const returnTravelData = JSON.parse(localStorage.getItem('returnTravelData'))

    const { date, gameDate } = outboundData
    const gameId = outboundData.game
    const postCode = outboundData.startPostcode

    const legs = outboundData?.legs.sort((a: any, b: any) => a.position - b.position)

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Track travel overview' })

        reloadStoredData()

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [pathname])

    const onClose = () => {
        clearTravelData(dispatch)
        // eslint-disable-next-line no-unused-expressions
        isEmbedded ? navigate('/') : navigate('/')
    }

    const lastItem = travelCommuteDetail?.multiDaySections
        ? travelCommuteDetail.multiDaySections[travelCommuteDetail.multiDaySections.length - 1]
        : undefined

    const saveCommute = async () => {
        setLoading(true)

        dispatch(
            commuteSave(
                {
                    commute: outboundData?.backendCommuteId || commuteStartEnd.id,
                    favouriteRouteName: heart ? favouriteRouteName || undefined : undefined,
                },
                (response) => {
                    if (response.survey) {
                        navigate(`/survey/${response?.survey?.id}`)
                    } else if (travelCommuteDetail?.multiDaySections?.length > 1 && user?.company?.singleUseDataCollection === 'prize_draw') {
                        navigate('/log/prize-draw')
                    } else if (travelCommuteDetail?.multiDaySections?.length > 1 && user?.company?.singleUseDataCollection === 'none') {
                        navigate('/')
                    } else {
                        navigate('/log/your-impact')
                    }
                    setLoading(false)
                },
            ),
        )
    }

    const sameAsOutbound = async () => {
        setLoading(true)

        const multipleSaveLegsData = outboundData?.legs.sort((a: ILeg, b: ILeg) => b.position - a.position)?.map((item: ILeg, index: number) => ({
            leg: item.id,
            transport: item?.transportId,
            transportSize: item?.transportSize?.id,
            transportFuelType: item?.transportFuelType?.id,
            countOfPeople: item?.countOfPeople,
            isGoingBack: true,
            startPlaceId: item.endPlaceId,
            startPlaceName: item.endPlaceName,
            startPlaceLon: item.endPlaceLon,
            startPlaceLat: item.endPlaceLat,
            endPlaceId: item.startPlaceId,
            endPlaceName: item.startPlaceName,
            endPlaceLon: item.startPlaceLon,
            endPlaceLat: item.startPlaceLat,
            position: index + 1,
        })) || []

        dispatch(multipleSaveLegs({ commute: outboundData?.backendCommuteId || commuteStartEnd.id, legs: multipleSaveLegsData, deleteReturnLegs: true }, async (response) => {
            // const travelData = JSON.parse(localStorage.getItem('travelData') || '{}')

            const newLegs = legInstancesToFormData(response.legs)

            localStorage.setItem('returnTravelData', JSON.stringify({
                ...returnTravelData,
                legs: newLegs,
                endPlaceId: travelCommuteDetail.startPlaceId || travelData.startPlaceId,
                endPlaceName: travelCommuteDetail.startPlaceName || travelData.startPlaceName,
                endPlaceLon: travelCommuteDetail.startPlaceLon || travelData.startPlaceLon,
                endPlaceLat: travelCommuteDetail.startPlaceLat || travelData.startPlaceLat,
                startPlaceId: lastItem?.endPlaceId || travelCommuteDetail.endPlaceId || travelData.endPlaceId,
                startPlaceName: lastItem?.endPlaceName || travelCommuteDetail.endPlaceName || travelData.endPlaceName,
                startPlaceLon: lastItem?.endPlaceLon || travelCommuteDetail.endPlaceLon || travelData.endPlaceLon,
                startPlaceLat: lastItem?.endPlaceLat || travelCommuteDetail.endPlaceLat || travelData.endPlaceLat,
            }))

            dispatch(changeCommuteDestination(outboundData, outboundData.backendCommuteId || commuteStartEnd.id, {
                returnJourneyStartPlaceId: lastItem?.endPlaceId || travelCommuteDetail.endPlaceId || outboundData.endPlaceId,
                returnJourneyStartPlaceName: lastItem?.endPlaceName || travelCommuteDetail.endPlaceName || outboundData.endPlaceName,
                returnJourneyStartPlaceLon: lastItem?.endPlaceLon || travelCommuteDetail.endPlaceLon || outboundData.endPlaceLon,
                returnJourneyStartPlaceLat: lastItem?.endPlaceLat || travelCommuteDetail.endPlaceLat || outboundData.endPlaceLat,
                returnJourneyEndPlaceId: travelCommuteDetail.startPlaceId || outboundData.startPlaceId,
                returnJourneyEndPlaceName: travelCommuteDetail.startPlaceName || outboundData.startPlaceName,
                returnJourneyEndPlaceLon: travelCommuteDetail.startPlaceLon || outboundData.startPlaceLon,
                returnJourneyEndPlaceLat: travelCommuteDetail.startPlaceLat || outboundData.startPlaceLat,
            }, () => {
                setLoading(false)
                saveCommute()
            }))
        }))
    }

    const onSubmit = (type: string) => {
        dispatch(clearLegTransport())

        // if (user?.id === '65') {
        //     navigate('/survey/1')
        //     return
        // }

        if (isReturnJourneyExists || lastItem?.endLocation === 'return') {
            saveCommute()
            return
        }

        if (type === 'one-way') {
            dispatch(changeCommuteDestination(outboundData, outboundData.backendCommuteId || commuteStartEnd.id, {
                returnJourneyStartPlaceId: null,
                returnJourneyStartPlaceName: null,
                returnJourneyStartPlaceLon: null,
                returnJourneyStartPlaceLat: null,
                returnJourneyEndPlaceId: null,
                returnJourneyEndPlaceName: null,
                returnJourneyEndPlaceLon: null,
                returnJourneyEndPlaceLat: null,
                deleteReturnLegs: true,
            }, () => {
                saveCommute()
            }))

            return
        }

        if (type === 'same-as-outbound') {
            sameAsOutbound()
            return
        }

        if (type === 'different-journey-same-destination') {
            dispatch(changeCommuteDestination(outboundData, outboundData.backendCommuteId || commuteStartEnd.id, {
                returnJourneyStartPlaceId: lastItem?.endPlaceId || travelCommuteDetail.endPlaceId || outboundData.endPlaceId,
                returnJourneyStartPlaceName: lastItem?.endPlaceName || travelCommuteDetail.endPlaceName || outboundData.endPlaceName,
                returnJourneyStartPlaceLon: lastItem?.endPlaceLon || travelCommuteDetail.endPlaceLon || outboundData.endPlaceLon,
                returnJourneyStartPlaceLat: lastItem?.endPlaceLat || travelCommuteDetail.endPlaceLat || outboundData.endPlaceLat,
                returnJourneyEndPlaceId: travelCommuteDetail.startPlaceId || outboundData.startPlaceId,
                returnJourneyEndPlaceName: travelCommuteDetail.startPlaceName || outboundData.startPlaceName,
                returnJourneyEndPlaceLon: travelCommuteDetail.startPlaceLon || outboundData.startPlaceLon,
                returnJourneyEndPlaceLat: travelCommuteDetail.startPlaceLat || outboundData.startPlaceLat,
                deleteReturnLegs: true,
            }, () => {
                navigate('/log/track-travel-modality', { state: { date, postCode, gameDate, goingBackCommute: true } })
            }))
            return
        }

        if (type === 'different-journey-different-destination') {
            navigate('/log/track-travel-start-end', { state: { returnTravel: true } })
        }
    }

    function goBack() {
        if (location?.state?.goingBackFromImpact || location?.state?.fromLogScreen) {
            navigate(-1)
            return
        }

        const item = legs[legs.length - 1]
        navigate('/log/track-travel-leg-start-end', { state: { choosedCar: item.choosedCar, gameDate, gameId, date: new Date(date), fuel: item.transportFuelType, postCode, sizeCar: item.transportSize, edit: true, goingBackCommute: isReturnJourneyExists, distanceNumberBack, prevUrl: location?.pathname } })
        localStorage.setItem('travelDataEdit', JSON.stringify({ ...item, legName: legs?.length }))
    }

    const { sponsorshipBlocks } = useSelector(getHomePageLists)
    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Log')

    const reloadStoredData = () => {
        dispatch(getTravelCommuteDetail(travelData?.backendCommuteId || commuteStartEnd?.id, (commuteDetail) => {
            const outboundDataWhenLoadingFromBackend = JSON.parse(localStorage.getItem('travelData') || '{}')
            const returnDataWhenLoadingFromBackend = JSON.parse(localStorage.getItem('returnTravelData') || '{}')

            const newOutboundData = {
                ...outboundDataWhenLoadingFromBackend,
                legs: legInstancesToFormData(commuteDetail.legs.filter((i) => !i.isGoingBack)),
            }

            const newReturnData = {
                ...returnDataWhenLoadingFromBackend,
                legs: legInstancesToFormData(commuteDetail.legs.filter((i) => i.isGoingBack)),
            }

            localStorage.setItem('travelData', JSON.stringify(newOutboundData))

            localStorage.setItem('returnTravelData', JSON.stringify(newReturnData))

            setOutboundData(newOutboundData)
            setReturnData(newReturnData)
        }))
    }

    const onChangeType = (type: string) => {
        setSelectedReturnJourneyType(type)
        onSubmit(type)
    }

    const travelData = JSON.parse(localStorage.getItem('travelData') || '{}')
    const tripsCount = ((travelCommuteDetail?.multiDaySections?.length || 2) - (lastItem?.endLocation === 'return' ? 2 : 1)) / 2

    return (
        <Layout showFooter={false} spaceOnBottom={!isEmbedded} maxWidth={false}>
            <div className="w-full h-screen">
                <div style={{ marginTop: navbarHeight, left: '50%', transform: 'translateX(-50%)' }}
                    className="fixed top-0 left-0 w-full z-10 bg-white max-w-[1440px]">
                    {sponsoredBlock && <div className="mobile:hidden"><SponsoredByBlock item={sponsoredBlock} /></div>}

                    <div className="flex justify-between items-center h-[53px] pr-2 mobile:mx-[42px] mx-4">
                        <div onClick={goBack} className="flex justify-end cursor-pointer">
                            <ArrowTravel width={22} height={22} />
                        </div>

                        <div onClick={() => setShowQuizQuitOverlayModal(true)}
                            className="flex justify-end cursor-pointer">
                            <DismissIcon width={18} height={18} />
                        </div>
                    </div>
                </div>

                <div style={{ marginTop: screenWidth > 768 ? navbarHeight : !sponsoredBlock ? navbarHeight : 125 }} className="h-screen flex flex-col items-center mobile:px-[42px] px-4">
                    <div className="max-w-[600px] w-full pb-[300px]">
                        <h1 className="heading-uppercase heading-font text-22 mt-2 tracking-tight font-semibold text-cavolo">
                            {t('log.journey-summary')}
                        </h1>

                        <h1 className="heading-uppercase heading-font text-18 mt-5 tracking-tight font-semibold text-cavolo">
                            {!travelCommuteDetail?.multiDaySections?.length ? t('log.outbound') : travelCommuteDetail?.multiDaySections?.[0]?.end === 'accommodation' ? t('log.outbound-to-accommodation') : t('log.outbound-to-event')}
                        </h1>

                        {(travelCommuteDetail?.multiDaySections?.length || 0) > 1 ? (
                            <div>
                                <JourneyLegs containerStyle={{ marginTop: 10 }} reloadStoredData={reloadStoredData}
                                    journeyData={travelCommuteDetail?.multiDaySections?.[0]} />

                                <div onClick={() => setShowAddLegModal(travelCommuteDetail.multiDaySections?.[0])}
                                    className="flex items-center mt-4 pl-3 cursor-pointer">
                                    <PlusAddLeg />

                                    <h1 className="text-14 font-normal text-cavolo not-heading-font ml-1.5">
                                        {t('log.add-a-leg')}
                                    </h1>
                                </div>

                                <div className="my-4">
                                    <h1 className="heading-uppercase heading-font text-18 tracking-tight font-semibold text-cavolo">
                                        {travelCommuteDetail?.multiDaySections?.[1].start === 'accommodation' ? t('log.accommodation-to-event') : t('log.event-to-accommodation')}
                                    </h1>

                                    <h1 className="font-normal text-14 text-black">
                                        {replacePartsOfText(
                                            t(tripsCount <= 1 ? 'log.x-trip-over-n-days' : 'log.x-trips-over-n-days'),
                                            {
                                                '[TRIPS-COUNT]': tripsCount,
                                                '[TRIP-DAYS-COUNT]': travelData?.gameIds?.length || 0,
                                            },
                                        )}
                                    </h1>
                                </div>

                                <JourneyLegs containerStyle={{ marginTop: 10 }} reloadStoredData={reloadStoredData}
                                    journeyData={travelCommuteDetail.multiDaySections[1]} />

                                <div onClick={() => setShowAddLegModal(travelCommuteDetail.multiDaySections[1])}
                                    className="flex items-center mt-4 pl-3 cursor-pointer">
                                    <PlusAddLeg />

                                    <h1 className="text-14 font-normal text-cavolo not-heading-font ml-1.5">
                                        {t('log.add-a-leg')}
                                    </h1>
                                </div>

                                {lastItem?.endLocation === 'return' && (
                                    <div>
                                        <h1 className="my-4 heading-uppercase heading-font text-18 tracking-tight font-semibold text-cavolo">
                                            {lastItem?.startLocation === 'accommodation' ? t('log.return-from-accommodation') : t('log.return-from-event')}
                                        </h1>

                                        <JourneyLegs containerStyle={{ marginTop: 10 }}
                                            reloadStoredData={reloadStoredData}
                                            journeyData={lastItem} />

                                        <div onClick={() => setShowAddLegModal(lastItem)}
                                            className="flex items-center mt-4 pl-3 cursor-pointer">
                                            <PlusAddLeg />

                                            <h1 className="text-14 font-normal text-cavolo not-heading-font ml-1.5">
                                                {t('log.add-a-leg')}
                                            </h1>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div>
                                <JourneyLegs containerStyle={{ marginTop: 10 }} reloadStoredData={reloadStoredData}
                                    journeyData={outboundData} />

                                <div onClick={() => setShowAddLegModal('outbound')}
                                    className="flex items-center mt-4 pl-3 cursor-pointer">
                                    <PlusAddLeg />

                                    <h1 className="text-14 font-normal text-cavolo not-heading-font ml-1.5">
                                        {t('log.add-a-leg')}
                                    </h1>
                                </div>
                            </div>
                        )}

                        {isReturnJourneyExists && (
                            <div>
                                <h1 className="heading-uppercase heading-font text-18 mt-5 tracking-tight font-semibold text-cavolo">
                                    {!travelCommuteDetail?.multiDaySections?.length ? t('log.return') : travelCommuteDetail?.multiDaySections?.[0]?.end ? t('log.outbound-to-accommodation') : t('log.outbound-to-event')}
                                </h1>

                                <JourneyLegs containerStyle={{ marginTop: 10 }} reloadStoredData={reloadStoredData}
                                    goingBackCommute
                                    journeyData={returnData} />

                                <div onClick={() => setShowAddLegModal('return')}
                                    className="flex items-center mt-4 pl-3 cursor-pointer">
                                    <PlusAddLeg />

                                    <h1 className="text-14 font-normal text-cavolo not-heading-font ml-[7px]">
                                        {t('log.add-a-leg')}
                                    </h1>
                                </div>
                            </div>
                        )}

                        {/* <button */}
                        {/*    onClick={() => { */}
                        {/*        localStorage.setItem(goingBackCommute ? 'returnTravelData' : 'travelData', JSON.stringify({ ...outboundData, createLegPosition: (legs?.length || 0) + 1, legStartEndEmpty: true })) */}
                        {/*        navigate('/log/track-travel-modality', { state: { goingBackCommute, edit: true } }) */}
                        {/*    }} */}
                        {/*    className="px-6 border border-solid border-spinach h-[35px] mt-[24px] bg-pistachio font-bold rounded-md text-14 text-cavolo not-heading-font"> */}
                        {/*    {t('log.add-a-leg')} */}
                        {/* </button> */}

                        {!isReturnJourneyExists && lastItem?.endLocation !== 'return' && (
                            <div>
                                <h1 className="heading-font pt-8 text-18 tracking-tight font-semibold text-cavolo">
                                    {travelCommuteDetail?.multiDaySections?.length > 1 ? (
                                        lastItem?.endLocation === 'accommodation' ? t('log.return-from-accommodation') : t('log.return-from-event')
                                    ) : (
                                        t('log.return')
                                    )}
                                </h1>

                                <div className="flex mt-6">
                                    <ReturnTypes
                                        selectedReturnJourneyType={selectedReturnJourneyType}
                                        onChangeType={onChangeType} typeName="same-as-outbound"
                                        text={t('log.same-as-outbound')} Svg={SameReturnJourneyIcon} />

                                    <ReturnTypes
                                        selectedReturnJourneyType={selectedReturnJourneyType}
                                        onChangeType={onChangeType}
                                        typeName="different-journey-same-destination"
                                        text={t('log.different-journey-same-destination')}
                                        Svg={DifferentJourneySameDestinationIcon} />

                                    <ReturnTypes
                                        selectedReturnJourneyType={selectedReturnJourneyType}
                                        onChangeType={onChangeType}
                                        typeName="different-journey-different-destination"
                                        text={t('log.different-destination')}
                                        Svg={DifferentJourneyDifferentDestinationIcon} />

                                    <ReturnTypes
                                        selectedReturnJourneyType={selectedReturnJourneyType}
                                        onChangeType={onChangeType} typeName="one-way"
                                        text={t('log.no-return-one-way-journey')} Svg={OneWayJourneyIcon} />
                                </div>
                            </div>
                        )}

                        {(user?.company?.travelLogByFavourite === 'enabled_expanded' || user?.company?.travelLogByFavourite === 'enabled_collapsed') && (
                            <div className="mb-[27px]">
                                <div onClick={() => setHeart(!heart)}
                                    className="flex items-center mt-7 cursor-pointer">
                                    {heart ? (
                                        <HearthActiveIcon />
                                    ) : (
                                        <HearthDefaultIcon />
                                    )}

                                    <h1 className="text-14 tracking-tight text-raspberry ml-3">
                                        {t('log.save-as-favourite-route')}
                                    </h1>
                                </div>

                                {heart && (
                                    <div className="mt-5">
                                        <Formik onSubmit={console.log} initialValues={{ name: '' }}>
                                            <Form>
                                                <Input
                                                    value={favouriteRouteName}
                                                    onChange={(e: any) => setFavouriteRouteName(e.target.value)}
                                                    placeholder={t('log.enter-name')}
                                                    name="name"
                                                    type="text"
                                                    validate={required(t)} />
                                            </Form>
                                        </Formik>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                <div
                    className={`fixed bottom-0 ${isEmbedded ? 'h-[120px]' : 'h-[112px]'} bg-pistachio w-full duration-100`}
                    style={{ boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.15)' }}>
                    <div
                        className="mx-auto flex max-mobile:flex-col max-w-[848px] relative h-full px-[16px] mobile:px-[42px]">
                        <div className="flex-1 mt-7 mobile:mr-[40px] mr-[16px]" />

                        <div className={`${isEmbedded ? 'mb-4' : ''} mobile:ml-8 mobile:w-[180px] relative`}>
                            <Button
                                loading={loading}
                                onClick={() => onSubmit(selectedReturnJourneyType)}
                                className="absolute bottom-8 duration-100 not-heading-font"
                                text={isReturnJourneyExists ? t('global.save') : t('global.submit')} />
                        </div>
                    </div>
                </div>

                <QuizQuitOverlayModal
                    description={user?.company?.gamification ? t('log.quit-description-is-switched-on-gamification') : t('log.quit-description-is-switched-off-gamification')}
                    isShown={showQuizQuitOverlayModal}
                    onClose={onClose}
                    onContinue={() => setShowQuizQuitOverlayModal(false)} />

                <AddLegModal multiDaySection={typeof showAddLegModal !== 'string' ? showAddLegModal : undefined} goingBackCommute={showAddLegModal === 'return'} isShown={!!showAddLegModal}
                    onClose={() => setShowAddLegModal('')} />
            </div>
        </Layout>
    )
}
