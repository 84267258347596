import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ChevronRight, EditIcon, TrashIcon, TravelTreeBigEllipse } from '../common/Svg'
import { combineLegs } from '../../utils/travel'
import LegItem from './LegItem'
import { ILegs } from '../../types/data'
import { getWhiteLabelingList } from '../../state/user/selectors'
import { replaceAll } from '../../utils/helpers'
import { COLOR_CONST } from '../../constants'
import { travelSelector } from '../../state/travel/selectors'
import EditLegModal from './EditLegModal'

interface IProps {
    journeyData: any
    onDelete?: () => void
    impact?: boolean
    goingBackCommute?: boolean
    reloadStoredData?: () => void
    containerStyle?: object
}

export default function JourneyLegs({ journeyData, onDelete, impact, goingBackCommute, reloadStoredData, containerStyle }: IProps) {
    const navigate = useNavigate()
    const { transportModes } = useSelector(travelSelector)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const [t] = useTranslation('global')
    const legs = combineLegs((journeyData?.legs || []).sort((a: any, b: any) => a.position - b.position))
    const [showHideDetails, setShowHideDetails] = useState<boolean>(legs.length > 1)
    const [showEditLegModal, setShowEditLegModal] = useState<boolean>(false)
    const [editItem, setEditItem] = useState<ILegs>()

    const onSubmit = (items: ILegs) => {
        setEditItem(items)
        setShowEditLegModal(true)
    }

    return (
        <div className={cn('mt-5', { 'flex justify-between': impact })} style={containerStyle}>
            <div>
                <div className="flex items-end">
                    <div>
                        <TravelTreeBigEllipse />
                    </div>

                    <div className="text-16 text-cavolo font-semibold not-heading-font ml-2.5">
                        {journeyData?.startPlaceName}
                    </div>
                </div>

                {Array.isArray(legs) && showHideDetails ? (
                    <div className="border-spinach border-l-[10px] ml-3 pl-5 mt-[-3px] py-[15px]">
                        <div className="flex">
                            {legs.map((item: ILegs, index: number) => {
                                const lowerTransportName = item?.transportName?.toLowerCase() || item?.transport?.name?.toLowerCase()
                                const transportMode = transportModes.find((i) => i.name === lowerTransportName || i?.synonyms?.split(',').includes(lowerTransportName))
                                const isLast = index === legs.length - 1

                                return (
                                    <div key={item.backendLegId} className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            {/* eslint-disable-next-line react/no-danger */}
                                            <div onClick={() => onSubmit(item)} className="cursor-pointer" dangerouslySetInnerHTML={{ __html: replaceAll(transportMode?.icon || '', COLOR_CONST, whiteLabeling.cavolo) }} />

                                            {!isLast && (
                                                <div className="mx-2">
                                                    <ChevronRight />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                        <h1 onClick={() => setShowHideDetails(false)}
                            className="cursor-pointer mt-1 text-black underline underline-offset-2 text-14">
                            {t('log.show-details')}
                        </h1>
                    </div>
                ) : (
                    legs?.map((item: ILegs, index: number) => {
                        const isLast = index + 1 === legs.length
                        const previousEnd = index > 0 ? legs[index - 1].endPlaceName : journeyData.startPlaceName
                        const missingLegFromTop = item.startPlaceName !== previousEnd

                        return (
                            <div key={item.backendLegId}>
                                <LegItem
                                    journeyData={journeyData}
                                    reloadStoredData={reloadStoredData}
                                    legs={legs}
                                    goingBackCommute={goingBackCommute}
                                    impact={impact}
                                    item={item}
                                    missingLegFromTop={missingLegFromTop}
                                    isLast={isLast}
                                    index={index}
                                    onSubmit={onSubmit}
                                    showHideDetails={showHideDetails}
                                    setShowHideDetails={setShowHideDetails}
                                />
                            </div>
                        )
                    })
                )}

                {/* <h1 onClick={() => setShowDetails(false)} */}
                {/*    className="cursor-pointer mt-1 text-black underline underline-offset-2 text-14"> */}
                {/*    {t('log.hide-details')} */}
                {/* </h1> */}

                <div className="flex mt-[-2px]">
                    <div>
                        <TravelTreeBigEllipse />
                    </div>

                    <div className="text-16 text-cavolo font-semibold not-heading-font ml-2.5">
                        {journeyData?.endPlaceName}
                    </div>
                </div>
            </div>

            {impact && (
                <div className="flex items-center cursor-pointer">
                    <div onClick={() => navigate('/log/track-travel-overview', {
                        state: {
                            impact: true,
                            goingBackCommute,
                            goingBackFromImpact: impact,
                        },
                    })}>
                        <EditIcon />
                    </div>

                    <div onClick={onDelete}>
                        <TrashIcon />
                    </div>
                </div>
            )}

            {showEditLegModal && (
                <EditLegModal legs={legs} goingBackCommute={goingBackCommute} reloadStoredData={reloadStoredData} onClose={() => setShowEditLegModal(false)}
                    editItem={editItem} />
            )}
        </div>
    )
}
